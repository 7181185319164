import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import moment from 'moment';
import 'moment/locale/fr';
import {mixin} from "./mixins/mixin"
import {pageInfoMixin} from "./mixins/pageInfoMixin"
import {dateMixin} from "./mixins/dateMixin"
import CustomButton from "@/components/SmartEngage/Utils/CustomButton.vue";

require("./assets/css/custom.css")

Vue.component('CustomButton', CustomButton)

Vue.config.productionTip = false
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

moment().locale('fr');
Vue.prototype.$moment = moment

Vue.mixin(mixin)
Vue.mixin(pageInfoMixin)
Vue.mixin(dateMixin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
