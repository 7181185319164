import moment from 'moment';

export const dateMixin = {
    data: () => {
        return {
            dateTypes: {
                "picker": "YYYY-MM-DD",
                "fr": "DD/MM/YYYY",
                "en": "MM-DD-YYYY",
            },
            dateAndHourTypes: {
                "fr": "DD/MM/YYYY HH:mm:ss",
                "en": "MM-DD-YYYY HH:mm:ss",
            },
        }
    },
    methods: {
        currentDateTypeToUse(locale){
            return this.dateTypes[locale] || this.dateTypes['en']
        },
        currentDateAndHourTypeToUse(locale){
            return this.dateAndHourTypes[locale] || this.dateAndHourTypes['en']
        },


        formatDateEnToDateLocale(date, locale = this.$i18n.locale) {
            if (!date) return null;

            return moment(date, this.dateTypes['en']).format(this.currentDateTypeToUse(locale));
        },


        formatDateAndHourEnToDateLocale(date, locale = this.$i18n.locale) {
            if (!date) return null;

            return moment(date, this.dateAndHourTypes['en']).format(this.currentDateTypeToUse(locale));
        },
        formatDateAndHourEnToDateAndHourLocale(date, locale = this.$i18n.locale) {
            if (!date) return null;

            return moment(date, this.dateAndHourTypes['en']).format(this.currentDateAndHourTypeToUse(locale));
        },


        formatDatePickerToDateLocale(date, locale = this.$i18n.locale){
            if (!date) return null;

            return moment(date, this.dateTypes['picker']).format(this.currentDateTypeToUse(locale));
        },
        formatDateLocaleForPicker(date, locale = this.$i18n.locale) {
            if (!date) return null;

            return moment(date, this.currentDateTypeToUse(locale)).format(this.dateTypes['picker'])
        },
    },
}
