<template>
    <div class="sidebar-wrapper" permanent>
        <div class="items-wrapper">
            <div :class="{'item': true, 'active': item.isActive}" @click="handleClick(item.routeName)" v-for="item in items" :key="item.title" link>
                <div class="item-icon">
                    <v-icon>{{ item.icon }}</v-icon>
                </div>

                <div class="item-title">
                    <span>{{ item.title }}</span>
                </div>
            </div>
        </div>
        <v-btn class="pa-2 my-4 logout-btn" depressed color="primary" x-small @click="logout">{{$t('sidebar.logout')}}<v-icon x-small>mdi-logout</v-icon></v-btn>
    </div>
</template>

<script>

export default {
    name: 'Sidebar',
    data() {
        return {
            refreshKey: 0,
            items: [],
            itemActiveId: 0
        }
    },
    created() {
        this.setItem()

        this.$root.$on('changeLang', () => {
            this.setItem()
        });

        this.itemIsActive(this.$route)
    },
    methods:{
        setItem(){
            this.items = [
                {
                    id: 0,
                    title: this.$t('sidebar.dashboard'),
                    icon: 'mdi-view-dashboard',
                    parent: 'IEC',
                    routeName: "Dashboard",
                    isActive: this.itemActiveId === 0
                },
                {
                    id: 1,
                    title: this.$t('sidebar.stats'),
                    icon: 'mdi-poll',
                    parent: 'IEC',
                    routeName: "Stats",
                    isActive: this.itemActiveId === 1
                },
                {
                    id: 2,
                    title: this.$t('sidebar.check'),
                    icon: 'mdi-check-circle-outline',
                    parent: 'IEC',
                    routeName: "Check",
                    isActive: this.itemActiveId === 2
                },
                {
                    id: 3,
                    title: this.$t('sidebar.orders'),
                    icon: 'mdi-shopping-outline',
                    parent: 'IEC',
                    routeName: "Orders",
                    isActive: this.itemActiveId === 3
                },
                {
                    id: 4,
                    title: this.$t('sidebar.suspectBasketConfiguration'),
                    icon: 'mdi-alert-outline',
                    parent: 'IEC',
                    routeName: "SuspectBasketConfiguration",
                    isActive: this.itemActiveId === 4
                },
                {
                    id: 5,
                    title: this.$t('sidebar.smartEngage'),
                    icon: 'mdi-monitor-cellphone',
                    parent: 'SmartEngage',
                    routeName: "Campaigns",
                    isActive: this.itemActiveId === 5
                }
            ]
        },
        handleClick(routeName){
            this.$router.push({name: routeName})
        },
        logout(){
            localStorage.removeItem("IEC_BO_LOGIN")
            this.$router.push({name: 'Login'})
        },
        itemIsActive(route){
            this.items.forEach(item => {
                item.isActive = false
                if(route.name === item.routeName || (route.meta.parent && route.meta.parent === item.parent)){
                    item.isActive = true
                    this.itemActiveId = item.id
                }
            })
        }
    },
    watch:{
        "$route":function (newVal) {
            this.itemIsActive(newVal)
        }
    }
};
</script>
<style scoped>
.sidebar-wrapper{
    margin: auto 0;
    left: 0;
    min-height: 100%;
    box-shadow: 5px 0px 10px #80808052;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.items-wrapper{
    height: 100%;
    justify-content: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.item{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 32px;
    cursor: pointer;
}
.item:hover{
    background: rgba(0,0,0,.1);
}
.item.active{
    border-right: solid var(--primary-color);
    box-shadow: 0px 0px 8px #80808052;
}
.item-title{
    margin: 0 8px;
    font-size: .75rem;
    text-align: center;
}
.logout-btn{
    font-size: .5rem !important;
}
</style>
<style>
.v-navigation-drawer__content::-webkit-scrollbar {
    width: 5px;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    border-radius: 20px;
}
</style>
