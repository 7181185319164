<template>
  <v-dialog v-model="show" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text class="card-text">
        {{$t('loader')}}
        <v-progress-linear indeterminate color="white" class="mt-5"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Loader",
  data: () => ({
    show: false
  }),
  created() {
    this.$root.$on('showLoader', () => {
      this.show = true
    });
    this.$root.$on('hideLoader', () => {
      this.show = false
    });
  }
}
</script>

<style scoped>
  .card-text{
    padding: 16px !important;
    text-align: center;
    color: white !important;
  }
</style>