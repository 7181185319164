export const pageInfo = {
    namespaced: true,
    state: {
        pageInfo: null
    },
    mutations: {
        UPDATE_PAGE_INFO(state, pageInfo) {
            state.pageInfo = pageInfo;
        }
    },
    getters: {
        showSideBar(state){
            return state.pageInfo != null && state.pageInfo.showSidebar !== false
        },
        showHeader(state){
            return state.pageInfo != null && state.pageInfo.showHeader !== false
        },
        parentPage(state){
            return state.pageInfo?.parentPage || ""
        }
    },
    actions: {
        updatePageInfo({commit}, payload) {
            commit('UPDATE_PAGE_INFO', payload);
        }
    }
};
