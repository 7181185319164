<template>
  <v-snackbar
      v-model="show"
      :timeout="timeout * 1000"
      :color="color">

    {{content}}

    <template v-slot:action="{ attrs }" v-if="closable">
      <v-icon
          v-bind="attrs"
          @click="show = false"
      >
        mdi-close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data () {
    return {
      show: false,
      timeout: 5,
      color: "primary",
      content: "Content",
      closable: true
    }
  },
  created(){
    this.$root.$on('showAlert', (args) => {
      this.show = true
      this.content = args.content
      this.color = args.color
      this.timeout = args.timeout
      this.closable = args.closable
    });
    this.$root.$on('hideAlert', () => {
      this.show = false
    });
  },
  methods: {
  },
  watch:{
  }
}
</script>

<style scoped>

</style>