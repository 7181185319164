<template>

    <v-app>
        <v-navigation-drawer app class="rounded-tr-xl elevation-5" width="150" v-if="showSideBar" >
            <Sidebar />
        </v-navigation-drawer>

        <v-app-bar color="white" elevation="0" class="px-8" v-if="showHeader" app height="100px">
            <Header></Header>
        </v-app-bar>

        <v-main>
            <v-container fluid class="full-height" :class="{'pa-0': !showSideBar && !showHeader, 'px-16 py-8': $route.name !== 'Login' }">
                <router-view></router-view>
            </v-container>
        </v-main>

        <Loader></Loader>
        <Snackbar></Snackbar>
    </v-app>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue"
import Header from "@/components/Header.vue"
import Loader from "@/components/Utils/Loader";
import Snackbar from "@/components/Utils/Snackbar";
export default {
    name: 'App',
    components: {Snackbar, Loader, Sidebar, Header},
    data: () => ({
        //
    }),
    created() {
        if(this.isNull(localStorage.getItem('IEC_BO_store'))){
            localStorage.setItem('IEC_BO_store', JSON.stringify(this.$t('header.stores[0]')));
        }
    }
};
</script>
<style scoped>
</style>
