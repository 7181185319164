<template>
    <v-btn
        :class="{'full-width': fullWidth}"
        :color="color"
        :dark="dark"
        :elevation="elevation"
        :large="large"
        :loading="loading"
        :outlined="outlined"
        :ripple="!disabled"
        :small="small"
        :style="{ opacity }"
        :width="width"
        :x-small="xSmall"
        class="custom-button text-uppercase" @click="handleClick($event)">
        <slot/>
    </v-btn>
</template>

<script>
export default {
    name: "CustomButton",
    props: {
        loading: {
            type: Boolean
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: "primary"
        },
        small: {
            type: Boolean,
            default: false
        },
        xSmall: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        elevation: {
            type: Number,
            default: 0
        },
        width: {
            type: String,
            default: 'auto'
        },
        dark: {
            type: Boolean,
            default: true
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClick(event) {
            if (this.disabled) {
                event.preventDefault()
            } else {
                this.$emit("click", event)
            }
        }
    },
    computed: {
        opacity() {
            return this.disabled ? 0.5 : 1
        }
    },
}
</script>
<style scoped>
.full-width {
    width: 100% !important;
}
</style>
