import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
            primary: "#005A8C",
            secondary: "#ffffff",
            accent: "#C8CFE1",
            customGrey: "#B7B7B7",
            btnPrimary:"#005A8C",
            darkGrey: "#606060",
            lightGrey: "#f5f5f5",
            terminalModalPreview:"#46BEAA",
            terminalModalPreviewHeaderBackground:'#2D8C8C'
        },
      },
    },
});
