export const config = {
    WS_URL: "https://weshop-ws-v2-weshop.pubqlf.co.as8677.net",
    SMART_ENGAGE_WS_URL: "https://smart-engage-ws-wldd-events.apps.dev.caas4noprd.worldline-solutions.com",
    OPEN_FOOD_FACTS_URL: "https://world.openfoodfacts.org/api/v0",
    IMG_URL: "https://weshop-ws-v2-weshop.pubqlf.co.as8677.net",
    VERSION: "1.0.0"
}

export function isNotNull(val){
    if(val != null && val !== "" && val !== "undefined" && val !== undefined && val !== "-1" && val !== -1 && val !== "[]"){
        if(!Array.isArray(val)){
            return true;
        }else if(val.length > 0){
            return true;
        }
    }
    return false;
}

export function isNull(val){
    if(val == null || val === "" || val === "undefined" || val === undefined || val === "-1" || val === -1 || val === "[]"){
        return true;
    }else{
        if(!Array.isArray(val)){
            return false;
        }else if(val.length === 0){
            return true;
        }
    }
    return false;
}
