<template>
    <div class="header full-width full-height">
        <div class="logo">
            <v-img width="250" src="@/assets/img/logo_worldline.png" />
        </div>

        <div class="smart-engage" v-if="inCampaigns || inScenarios">
            <v-btn
                color="primary"
                dark
                elevation="0"
                class="selected-store-button mx-1"
                min-width="250"
                :outlined="!inCampaigns"
                @click="$router.push({name: 'Campaigns'})"
            >
                <span class="selected-store">{{$t('header.smartEngageCampaigns')}}</span>
            </v-btn>
            <v-btn
                color="primary"
                dark
                elevation="0"
                class="selected-store-button mx-1"
                min-width="250"
                :outlined="!inScenarios"
                @click="$router.push({name: 'Scenarios'})"
            >
                <span class="selected-store">{{$t('header.smartEngageScenarios')}}</span>
            </v-btn>
        </div>

        <div class="actions">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        elevation="0"
                        class="selected-store-button mx-1"
                        min-width="250"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <span class="selected-store">{{selectedStore.name}}</span>
                        <v-icon color="white" class="ms-2">mdi-storefront-outline</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="store in $t('header.stores')" :key="store.id" @click="changeStore(store)">
                        <v-list-item-title>{{store.name}}</v-list-item-title>

                        <v-list-item-icon>
                            <v-icon x-small color="primary" v-if="selectedStore.id === store.id">mdi-checkbox-blank-circle</v-icon>
                            <v-icon x-small color="primary" v-else>mdi-checkbox-blank-circle-outline</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="pa-1 mx-2 lang"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <span>{{$i18n.locale.toUpperCase()}}</span>
                    </v-btn>
                </template>

                <v-list v-for="(lang, key) in availableLocales" :key="key">
                    <v-list-item @click="changeLang(lang)">
                        <v-list-item-title>{{ lang.toUpperCase() }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selectedStore: "",
            availableLocales: this.$i18n.availableLocales || [],
        }
    },
    computed:{
      inCampaigns(){
          return this.$route.meta.parentPage && this.$route.meta.parentPage === "Campaigns"
      },
      inScenarios(){
          return this.$route.meta.parentPage && this.$route.meta.parentPage === "Scenarios"
      }
    },
    created() {
        this.selectedStore = JSON.parse(localStorage.getItem('IEC_BO_store'))
        if(this.isNull(this.selectedStore)){
            this.selectedStore = this.$t("header.stores")[0]
        }
    },
    methods:{
        changeLang(lang){
            localStorage.setItem('IEC_BO_lang', lang);
            this.$i18n.locale = lang;
            this.$root.$emit('changeLang');
        },
        changeStore(store){
            this.selectedStore = store;
            localStorage.setItem('IEC_BO_store', JSON.stringify(store));
            this.$root.$emit('changeStore');
        }
    }
}
</script>
<style scoped>
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px var(--primary-color);
}
.selected-store-button{
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 1.1rem;
    text-transform: none;
}
.selected-store{
    display: flex;
    align-items: center;
}
.header .actions{
    display: flex;
    align-items: center;
    justify-content: center;

}
.header .actions .lang{
    border: solid;
    border-radius: 10px;
    font-size: .8rem;
    font-weight: bold;
}
</style>
