import Vue from 'vue'
import Vuex from 'vuex'
import { pageInfo } from "@/store/modules/pageInfo"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    pageInfo,
  }
})
