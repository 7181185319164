import Vue from 'vue'
import VueRouter from 'vue-router'
import {isNull} from '@/assets/js/Utils.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      showSidebar: false,
      showHeader: false
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/IEC/Home.vue')
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () => import('@/views/IEC/Stats.vue')
  },
  {
    path: '/check',
    name: 'Check',
    component: () => import('@/views/IEC/Scan.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/IEC/Orders.vue')
  },
  {
    path: '/basket/suspect/configuration',
    name: 'SuspectBasketConfiguration',
    component: () => import('@/views/IEC/SuspectBasketConfiguration.vue')
  },
  {
    path: '/smart-engage/campaigns',
    name: 'Campaigns',
    component: () => import('@/views/SmartEngage/Campaigns.vue'),
    meta: {
      parent: "SmartEngage",
      parentPage: "Campaigns",
    }
  },
  {
    path: '/smart-engage/manage/campaign',
    name: 'ManageCampaign',
    component: () => import('@/views/SmartEngage/ManageCampaign.vue'),
    meta: {
      parent: "SmartEngage",
      parentPage: "Campaigns",
    }
  },
  {
    path: '/smart-engage/screens',
    name: 'Screens',
    component: () => import('@/views/SmartEngage/Screens.vue'),
    meta: {
      parent: "SmartEngage",
      parentPage: "Scenarios",
    }
  },
  {
    path: '/smart-engage/scenarios',
    name: 'Scenarios',
    component: () => import('@/views/SmartEngage/Scenarios.vue'),
    meta: {
      parent: "SmartEngage",
      parentPage: "Scenarios",
    }
  },
  {
    path: '/smart-engage/add/scenario',
    name: 'AddScenario',
    component: () => import('@/views/SmartEngage/AddScenario.vue'),
    meta: {
      parent: "SmartEngage",
      parentPage: "Scenarios",
    }
  },
  {
    path: '/smart-engage/stats',
    name: 'StatsSmartEngage',
    component: () => import('@/views/SmartEngage/Stats.vue'),
    meta: {
      parent: "SmartEngage",
      parentPage: "Campaigns",
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let userLogged = localStorage.getItem("IEC_BO_LOGIN")
  if(isNull(userLogged) && to.name !== 'Login'){
    next({ name: 'Login' })
  }
  next()
})

export default router
